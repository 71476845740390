import { useCallback, useContext } from 'react';

import { ModalDispatchContext } from '../context/modalContext';
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '../globals';
import { DeviceTypes } from '../utils/getDeviceType';

export const useGetAppOrSignUpHandler = (deviceType?: DeviceTypes) => {
  const setModalState = useContext(ModalDispatchContext);

  return useCallback(() => {
    if (deviceType === DeviceTypes.Desktop) {
      setModalState({ signupVisible: true });
      return;
    }

    if (deviceType === DeviceTypes.Android) {
      window.location.assign(ANDROID_DOWNLOAD_LINK);
    }

    if (deviceType === DeviceTypes.IOS) {
      window.location.assign(IOS_DOWNLOAD_LINK);
    }
  }, [deviceType, setModalState]);
};
