import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { useGetAppOrSignUpHandler } from '../../../../../hooks/useGetAppOrSignUpHandler';
import { CopiesSetData } from '../../../../../pages';
import { addUrlProtocol } from '../../../../../utils/addUrlProtocol';
import { DeviceTypes } from '../../../../../utils/getDeviceType';
import * as S from './ProductDescription.styles';

export const ProductDescription = ({
  deviceType,
  copies,
}: {
  deviceType: DeviceTypes;
  copies: CopiesSetData;
}) => {
  const handleClickCta = useGetAppOrSignUpHandler(deviceType);
  return (
    <S.ProductDescriptionWrapper>
      <S.ProductiveContainer>
        {copies.secondScreenImage?.fields?.file?.url && (
          <S.ProductiveImage
            alt={copies.secondScreenImageAlt || ''}
            loading="lazy"
            src={copies.secondScreenImage.fields.file.url}
          />
        )}
        <S.ProductiveMaxWidth>
          <S.Wrapper>
            <S.ProductiveContent />
            <S.ProductiveContent>
              {copies.secondScreenTitle && (
                <S.Title dangerouslySetInnerHTML={{ __html: copies.secondScreenTitle }} />
              )}
              <S.Text dangerouslySetInnerHTML={{ __html: copies.secondScreenDescription }} />
              {copies.secondScreenLinkText && (
                <S.LinkStyled
                  dangerouslySetInnerHTML={{ __html: copies.secondScreenLinkText }}
                  onClick={handleClickCta}
                />
              )}
            </S.ProductiveContent>
          </S.Wrapper>
        </S.ProductiveMaxWidth>
      </S.ProductiveContainer>
      <S.ScienceContainer>
        <S.Wrapper>
          <S.ScienceContent>
            <S.ScienceStaticData>
              <S.ScienceGeneralInfo>
                {copies.thirdScreenTitle && (
                  <S.Title dangerouslySetInnerHTML={{ __html: copies.thirdScreenTitle }} />
                )}
                {copies.thirdScreenDescription && (
                  <S.Text dangerouslySetInnerHTML={{ __html: copies.thirdScreenDescription }} />
                )}
              </S.ScienceGeneralInfo>
              <S.LabelInfo>
                {copies.thirdScreenBadgeImage?.fields?.file?.url && (
                  <Image
                    alt={copies.thirdScreenBadgeImageAlt || ''}
                    height={96}
                    src={addUrlProtocol(copies.thirdScreenBadgeImage.fields.file.url)}
                    width={335}
                  />
                )}
              </S.LabelInfo>
            </S.ScienceStaticData>
            <S.ScienceLinkContentStyled>
              {copies.thirdScreenLinkText && (
                <>
                  <Link
                    dangerouslySetInnerHTML={{ __html: copies.thirdScreenLinkText }}
                    href="/science"
                  />
                  <img alt="arrow" src="https://cdn.brain.fm/icons/arrow-right.svg" />
                </>
              )}
            </S.ScienceLinkContentStyled>
          </S.ScienceContent>
        </S.Wrapper>
        {copies.thirdScreenImage?.fields?.file?.url && (
          <S.ScienceImage
            alt={copies.thirdScreenImageAlt || ''}
            loading="lazy"
            src={copies.thirdScreenImage.fields?.file.url}
          />
        )}
        <S.ScienceLinkAfterContentStyled>
          {copies.thirdScreenLinkText && (
            <>
              <Link
                dangerouslySetInnerHTML={{ __html: copies.thirdScreenLinkText }}
                href="/science"
              />
              <img alt="arrow" src="https://cdn.brain.fm/icons/arrow-right.svg" />
            </>
          )}
        </S.ScienceLinkAfterContentStyled>
      </S.ScienceContainer>
    </S.ProductDescriptionWrapper>
  );
};
