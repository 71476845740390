import styled, { css } from 'styled-components';

import { mediaQuery } from '../../../../../styles/global';
import { H2 } from '../../../../_core/Typography';
import MaxWidth from '../../../../shared/MaxWidth';

export const ProductiveContainer = styled.div`
  position: relative;
  padding: 0 20px;
  z-index: 1;
`;
export const ScienceContainer = styled.div`
  position: relative;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;
  ${mediaQuery.maxWidth.desktop} {
    display: flex;
    flex-direction: column;
  }
`;
export const Wrapper = styled.div<{ reverse?: boolean }>`
  width: 100%;
  display: flex;
  ${mediaQuery.maxWidth.desktop} {
    display: initial;
  }
`;
export const ProductiveContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 12rem 20px;
  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    padding: 0 20%;
  }
  ${mediaQuery.maxWidth.tablet} {
    padding: 0;
  }
`;

export const LabelInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  ${mediaQuery.maxWidth.tablet} {
    img {
      max-width: 350px;
      width: 100%;
      height: auto;
    }
  }
`;

export const ScienceContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7rem 2rem 7rem;

  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    padding: 7rem 2rem 3rem;
  }

  ${mediaQuery.maxWidth.tablet} {
    width: 100%;
    padding: 1rem 0 1rem;
  }
`;

export const ButtonLinkStyles = css`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 48px;
  padding: 12px 32px;
  align-self: flex-start;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  a {
    text-decoration: none;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    letter-spacing: 1px;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    ${mediaQuery.maxWidth.tablet} {
      font-size: 0.65rem;
    }
  }
  &:hover {
    scale: 1.1;
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const ScienceLinkContentStyled = styled.p`
  ${ButtonLinkStyles};
  ${mediaQuery.maxWidth.tablet} {
    display: none;
  }
`;
export const ScienceLinkAfterContentStyled = styled.p`
  ${ButtonLinkStyles};
  display: none;
  ${mediaQuery.maxWidth.tablet} {
    display: flex;
    width: 100%;
    &:hover {
      scale: 1;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
`;

export const ScienceStaticData = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQuery.maxWidth.desktop} {
    flex-direction: row;
    gap: 20px;
  }
  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
    gap: 0;
  }
`;
export const ScienceGeneralInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(H2)`
  width: 85%;

  ${mediaQuery.maxWidth.tablet} {
    font-size: 1.5rem !important;
  }
`;
export const LinkStyled = styled.p`
  color: ${({ theme }) => theme.colors.blueLight};
  cursor: pointer;
  a {
    color: ${({ theme }) => theme.colors.blueLight};
    text-decoration: none;
  }
`;

export const Text = styled.p``;

export const ProductiveImage = styled.img`
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  object-fit: contain;
  z-index: -1;
  ${mediaQuery.maxWidth.desktop} {
    text-align: center;
    width: 125%;
    max-width: 600px;
    position: relative;
    top: unset;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  ${mediaQuery.minWidth.custom(1600)} {
    max-width: 750px;
    left: 8vw;
  }
  ${mediaQuery.minWidth.custom(2200)} {
    left: 12vw;
  }
  ${mediaQuery.minWidth.custom(2900)} {
    left: 10vw;
  }
`;
export const ScienceImage = styled.img`
  height: auto;
  object-fit: contain;
  z-index: -1;
  max-width: 520px;
  align-self: center;
  ${mediaQuery.maxWidth.tablet} {
    max-width: 100%;
  }
`;

export const ProductiveMaxWidth = styled(MaxWidth)`
  ${mediaQuery.maxWidth.desktop} {
    position: relative;
    top: -5vw;
  }
  ${mediaQuery.maxWidth.tablet} {
    top: -12vw;
  }
`;

export const ProductDescriptionWrapper = styled.div`
  position: relative;
  max-width: 2200px;
  margin: 0 auto;
`;
